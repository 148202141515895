export const STORAGE_USER_KEY = '_transport_user';
export const STORAGE_REMEMBER_KEY = '_transport_remember_user';
export const STORAGE_LAST_UNLOAD_KEY = '_transport_last_unload_time';

export const ADMIN_PROFILE = 'ADMINISTRADOR';
export const FINANCIAL_PROFILE = 'FINANCEIRO';
export const OPERATION_PROFILE = 'OPERAÇÃO';
export const SAC_PROFILE = 'SAC';

export const ROWS_PER_PAGE_IN_CANVAS = 36;

export const PROFILES = {
  [ADMIN_PROFILE]: { roles: ['/home', '/drivers', '/offers', '/orders', '/occurrences', '/occurrence', '/configuration', '/notifications', '/create/occurrences'], },
  [FINANCIAL_PROFILE]: { roles: ['/home', '/drivers', '/offers', '/orders', '/occurrences', '/occurrence', '/create/occurrences'] },
  [OPERATION_PROFILE]: { roles: ['/home', '/drivers', '/offers', '/orders', '/occurrences', '/occurrence', '/create/occurrences'] },
  [SAC_PROFILE]: { roles: ['/home', '/occurrences', '/occurrence', '/create/occurrences'] },
};

export const USER_STATUS = {
  PENDING: 'PENDING',
  DENIED: 'DENIED',
  APPROVED: 'APPROVED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
};

export const FILTER_LABEL_USER_STATUS = {
  PENDING: 'Pendente',
  DENIED: 'Negado',
  APPROVED: 'Aprovado',
  ACKNOWLEDGED: 'Reconhecido',
};

export const IMAGE_STATUS = {
  PENDING: 'PENDING',
  DENIED: 'DENIED',
  APPROVED: 'APPROVED',
  ALTERED: 'ALTERED',
};

export const FREIGHT_STATUS = {
  WAITING: 'WAITING',
  CANCELED: 'CANCELED',
  DRIVER_SELECTED: 'DRIVER_SELECTED',
  APPROVED: 'APPROVED',
  PICKUP_ORDER_SENT: 'PICKUP_ORDER_SENT',
  DRIVER_ARRIVED: 'DRIVER_ARRIVED',
  LOADING_STARTED: 'LOADING_STARTED',
  LOADING_FINISHED: 'LOADING_FINISHED',
  INVOICE_SENT: 'INVOICE_SENT',
  DELIVERY_STARTED: 'DELIVERY_STARTED',
  DELIVERY_FINISHED: 'DELIVERY_FINISHED',
  UNLOADING_STARTED: 'UNLOADING_STARTED',
  UNLOADING_FINISHED: 'UNLOADING_FINISHED',
  INVOICE_COUPON_SENT: 'INVOICE_COUPON_SENT',
  INVOICE_COUPON_REFUSED: 'INVOICE_COUPON_REFUSED',
  FINISHED: 'FINISHED',
};

export const FREIGHT_STATUS_CATEGORIES = {
  WAITING: 'Aguardando aprovação',
  APPROVED: 'Aprovado',
  INVOICE_COUPON_SENT: 'Comprovantes de descarga enviados',
  INVOICE_COUPON_REFUSED: 'Comprovantes de descarga recusados',
  IN_PROGRESS: 'Frete em andamento',
  CANCELED: 'Frete cancelado',
  FINISHED: 'Frete finalizado',
};

export const FREIGHT_STATUS_CATEGORY_MAP = {
  WAITING: 'WAITING',
  DRIVER_SELECTED: 'WAITING',
  APPROVED: 'APPROVED',
  PICKUP_ORDER_SENT: 'IN_PROGRESS',
  DRIVER_ARRIVED: 'IN_PROGRESS',
  LOADING_STARTED: 'IN_PROGRESS',
  LOADING_FINISHED: 'IN_PROGRESS',
  INVOICE_SENT: 'IN_PROGRESS',
  DELIVERY_STARTED: 'IN_PROGRESS',
  DELIVERY_FINISHED: 'IN_PROGRESS',
  UNLOADING_STARTED: 'IN_PROGRESS',
  UNLOADING_FINISHED: 'IN_PROGRESS',
  INVOICE_COUPON_SENT: 'INVOICE_COUPON_SENT',
  INVOICE_COUPON_REFUSED: 'INVOICE_COUPON_REFUSED',
  CANCELED: 'CANCELED',
  FINISHED: 'FINISHED',
};

export const FREIGHT_STATUS_LABELS = {
  WAITING: 'Aguardando aprovação',
  CANCELED: 'Cancelado',
  DRIVER_SELECTED: 'Motorista Selecionado',
  APPROVED: 'Aprovado',
  PICKUP_ORDER_SENT: 'Ordem de Coleta Enviada',
  DRIVER_ARRIVED: 'Motorista Chegou',
  LOADING_STARTED: 'Carregamento Iniciado',
  LOADING_FINISHED: 'Carregamento Concluído',
  INVOICE_SENT: 'Nota Fiscal Enviada',
  DELIVERY_STARTED: 'Entrega Iniciada',
  DELIVERY_FINISHED: 'Entrega Concluída',
  UNLOADING_STARTED: 'Descarregamento Iniciado',
  UNLOADING_FINISHED: 'Descarregamento Concluído',
  INVOICE_COUPON_SENT: 'Comprovantes Recebidos',
  INVOICE_COUPON_REFUSED: 'Comprovantes Rejeitados',
  FINISHED: 'Finalizado',
};

export const FREIGHT_USER_STATUS = {
  WAITING: 'WAITING',
  USER_ORDER_SENT: 'USER_ORDER_SENT',
  USER_ORDER_SENT_REFUSED: 'USER_ORDER_SENT_REFUSED',
  FINISHED: 'FINISHED',
};

export const FREIGHT_USER_STATUS_LABELS = {
  WAITING: 'Aguardando',
  USER_ORDER_SENT: 'Ordem de Serviço Enviada',
  USER_ORDER_SENT_REFUSED: 'Ordem de Serviço Recusada',
  FINISHED: 'Finalizado',
};

export const REQUEST_FREIGHT_STATUS = {
  WAITING: 'WAITING',
  CANCELED: 'CANCELED',
  NEGATED: 'NEGATED',
  DENIED: 'DENIED',
  COMPLETED: 'COMPLETED',
};

export const ORDER_STATUS = {
  USER_ORDER_SENT: 'USER_ORDER_SENT',
  USER_ORDER_SENT_REFUSED: 'USER_ORDER_SENT_REFUSED',
  ADVANCE_PAYMENT: 'ADVANCE_PAYMENT',
  FINISHED: 'FINISHED',
};

export const SHORT_FREIGHT_STATUS = {
  WAITING: 'WAITING',
  CANCELED: 'CANCELED',
  FINISHED: 'FINISHED',
  IN_PROGRESS: [
    FREIGHT_STATUS.DRIVER_SELECTED,
    FREIGHT_STATUS.APPROVED,
    FREIGHT_STATUS.PICKUP_ORDER_SENT,
    FREIGHT_STATUS.DRIVER_ARRIVED,
    FREIGHT_STATUS.LOADING_STARTED,
    FREIGHT_STATUS.LOADING_FINISHED,
    FREIGHT_STATUS.INVOICE_SENT,
    FREIGHT_STATUS.DELIVERY_STARTED,
    FREIGHT_STATUS.DELIVERY_FINISHED,
    FREIGHT_STATUS.UNLOADING_STARTED,
    FREIGHT_STATUS.UNLOADING_FINISHED,
    FREIGHT_STATUS.INVOICE_COUPON_SENT,
    FREIGHT_STATUS.INVOICE_COUPON_REFUSED,
  ],
};


export const VEHICLE_TYPES = {
  UTILITARIO: 'Utilitário',
  VLC: 'VLC',
  TRES_QUARTOS: '3/4',
  TOCO: 'Toco',
  TRUCK: 'Truck',
  BI_TRUCK: 'Bi-Truck',
  CARRETA_SIMPLES: 'Carreta Simples',
  CARRETA_LS: 'Carreta LS',
  VANDERLEIA: 'Vanderleia'
};

export const VEHICLE_LABELS = {
  'Utilitário': 'Utilitário',
  'VLC': 'VLC',
  '3/4': '3/4',
  'Toco': 'Toco',
  'Truck': 'Truck',
  'Bi-Truck': 'Bi-Truck',
  'Carreta Simples': 'Carreta Simples',
  'Carreta LS': 'Carreta LS',
  'Vanderleia': 'Vanderleia'
};

export const TYPEBYBOARD_TYPES = {
  BAU: 'Baú',
  GRADE_BAIXA: 'Grade Baixa',
  REFRIGERADO: 'Refrigerado',
  FRIGORIFICO: 'Frigorífico',
  SIDER: 'Sider',
  GRANELEIRO: 'Graneleiro',
  PRANCHA: 'Prancha',
  PLATAFORMA: 'Plataforma',
  PORTA_CONTAINER: 'Porta Container',
  CACAMBA: 'Caçamba',
  CAVAQUEIRA: 'Cavaqueira',
  SILO: 'Silo',
  MUNCK: 'Munck',
  CEGONHA: 'Cegonha'
};

export const TYPEBYBOARD_LABELS = {
  'Baú': 'Baú',
  'Grade Baixa': 'Grade Baixa',
  'Refrigerado': 'Refrigerado',
  'Frigorífico': 'Frigorífico',
  'Sider': 'Sider',
  'Graneleiro': 'Graneleiro',
  'Prancha': 'Prancha',
  'Plataforma': 'Plataforma',
  'Porta Container': 'Porta Container',
  'Caçamba': 'Caçamba',
  'Cavaqueira': 'Cavaqueira',
  'Silo': 'Silo',
  'Munck': 'Munck',
  'Cegonha': 'Cegonha'
};

export const ELEGIBLE_VEHICLES = {
  Pesados: [
    VEHICLE_TYPES.CARRETA_SIMPLES,
    VEHICLE_TYPES.CARRETA_LS,
    VEHICLE_TYPES.VANDERLEIA
  ],
  Médios: [
    VEHICLE_TYPES.TRUCK,
    VEHICLE_TYPES.BI_TRUCK
  ],
  Leves: [
    VEHICLE_TYPES.UTILITARIO,
    VEHICLE_TYPES.VLC,
    VEHICLE_TYPES.TRES_QUARTOS,
    VEHICLE_TYPES.TOCO
  ]
};

export const ELEGIBLE_TYPEBYBOARDS = {
  Carrocerias: [
    TYPEBYBOARD_TYPES.BAU,
    TYPEBYBOARD_TYPES.GRADE_BAIXA,
    TYPEBYBOARD_TYPES.REFRIGERADO,
    TYPEBYBOARD_TYPES.FRIGORIFICO,
    TYPEBYBOARD_TYPES.SIDER,
    TYPEBYBOARD_TYPES.GRANELEIRO,
    TYPEBYBOARD_TYPES.PRANCHA,
    TYPEBYBOARD_TYPES.PLATAFORMA,
    TYPEBYBOARD_TYPES.PORTA_CONTAINER,
    TYPEBYBOARD_TYPES.CACAMBA,
    TYPEBYBOARD_TYPES.CAVAQUEIRA,
    TYPEBYBOARD_TYPES.SILO,
    TYPEBYBOARD_TYPES.MUNCK,
    TYPEBYBOARD_TYPES.CEGONHA
  ]
};

export const FILTER_LABEL_DRIVER = {
  truckType: [
    { label: 'Utilitário', value: VEHICLE_TYPES.UTILITARIO },
    { label: 'VLC', value: VEHICLE_TYPES.VLC },
    { label: '3/4', value: VEHICLE_TYPES.TRES_QUARTOS },
    { label: 'Toco', value: VEHICLE_TYPES.TOCO },
    { label: 'Truck', value: VEHICLE_TYPES.TRUCK },
    { label: 'Bi-Truck', value: VEHICLE_TYPES.BI_TRUCK },
    { label: 'Carreta Simples', value: VEHICLE_TYPES.CARRETA_SIMPLES },
    { label: 'Carreta LS', value: VEHICLE_TYPES.CARRETA_LS },
    { label: 'Vanderleia', value: VEHICLE_TYPES.VANDERLEIA }
  ],
  typeByBoardType: [
    { label: 'Baú', value: TYPEBYBOARD_TYPES.BAU },
    { label: 'Grade Baixa', value: TYPEBYBOARD_TYPES.GRADE_BAIXA },
    { label: 'Refrigerado', value: TYPEBYBOARD_TYPES.REFRIGERADO },
    { label: 'Frigorífico', value: TYPEBYBOARD_TYPES.FRIGORIFICO },
    { label: 'Sider', value: TYPEBYBOARD_TYPES.SIDER },
    { label: 'Graneleiro', value: TYPEBYBOARD_TYPES.GRANELEIRO },
    { label: 'Prancha', value: TYPEBYBOARD_TYPES.PRANCHA },
    { label: 'Plataforma', value: TYPEBYBOARD_TYPES.PLATAFORMA },
    { label: 'Porta Container', value: TYPEBYBOARD_TYPES.PORTA_CONTAINER },
    { label: 'Caçamba', value: TYPEBYBOARD_TYPES.CACAMBA },
    { label: 'Cavaqueira', value: TYPEBYBOARD_TYPES.CAVAQUEIRA },
    { label: 'Silo', value: TYPEBYBOARD_TYPES.SILO },
    { label: 'Munck', value: TYPEBYBOARD_TYPES.MUNCK },
    { label: 'Cegonha', value: TYPEBYBOARD_TYPES.CEGONHA }
  ],
  truckOwner: [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
  ],
  paymentType: [{ value: 'PAMCARD' }, { value: 'CONTA' }],
  registerStatus: [
    { label: 'Aprovado', value: USER_STATUS.APPROVED },
    { label: 'Bloqueado', value: USER_STATUS.DENIED },
    { label: 'Pendente', value: USER_STATUS.PENDING }
  ]
};

export function getAvailableBoardOptions(vehicleType) {
  const boardOptionsMap = {
    'Utilitário': ['Baú', 'Grade Baixa', 'Refrigerado', 'Frigorífico'],
    'VLC': ['Baú', 'Grade Baixa', 'Refrigerado', 'Frigorífico'],
    '3/4': ['Baú', 'Grade Baixa', 'Refrigerado', 'Frigorífico', 'Sider', 'Graneleiro'],
    'Toco': ['Baú', 'Grade Baixa', 'Refrigerado', 'Frigorífico', 'Sider', 'Graneleiro'],
    'Truck': ['Baú', 'Grade Baixa', 'Sider', 'Graneleiro', 'Prancha', 'Plataforma', 'Porta Container', 'Caçamba', 'Cavaqueira', 'Silo', 'Munck'],
    'Bi-Truck': ['Baú', 'Grade Baixa', 'Sider', 'Graneleiro', 'Prancha', 'Plataforma', 'Porta Container', 'Caçamba', 'Cavaqueira', 'Silo', 'Munck'],
    'Carreta Simples': ['Baú', 'Grade Baixa', 'Sider', 'Graneleiro', 'Prancha', 'Plataforma', 'Porta Container', 'Caçamba', 'Cavaqueira', 'Silo', 'Munck', 'Cegonha', 'Refrigerado', 'Frigorífico'],
    'Carreta LS': ['Baú', 'Grade Baixa', 'Sider', 'Graneleiro', 'Prancha', 'Plataforma', 'Porta Container', 'Caçamba', 'Cavaqueira', 'Silo', 'Munck', 'Cegonha', 'Refrigerado', 'Frigorífico'],
    'Vanderleia': ['Baú', 'Grade Baixa', 'Sider', 'Graneleiro', 'Prancha', 'Plataforma', 'Porta Container', 'Caçamba', 'Cavaqueira', 'Silo', 'Munck', 'Cegonha', 'Refrigerado', 'Frigorífico']
  };

  return boardOptionsMap[vehicleType] || [];
}


export const FILTER_LABEL_FREIGHT = {
  status: [
    { label: FREIGHT_STATUS_LABELS.WAITING, value: SHORT_FREIGHT_STATUS.WAITING },
    { label: FREIGHT_STATUS_LABELS.CANCELED, value: SHORT_FREIGHT_STATUS.CANCELED },
    { label: FREIGHT_STATUS_LABELS.DRIVER_SELECTED, value: FREIGHT_STATUS.DRIVER_SELECTED },
    { label: FREIGHT_STATUS_LABELS.APPROVED, value: FREIGHT_STATUS.APPROVED },
    { label: FREIGHT_STATUS_LABELS.PICKUP_ORDER_SENT, value: FREIGHT_STATUS.PICKUP_ORDER_SENT },
    { label: FREIGHT_STATUS_LABELS.DRIVER_ARRIVED, value: FREIGHT_STATUS.DRIVER_ARRIVED },
    { label: FREIGHT_STATUS_LABELS.LOADING_STARTED, value: FREIGHT_STATUS.LOADING_STARTED },
    { label: FREIGHT_STATUS_LABELS.LOADING_FINISHED, value: FREIGHT_STATUS.LOADING_FINISHED },
    { label: FREIGHT_STATUS_LABELS.INVOICE_SENT, value: FREIGHT_STATUS.INVOICE_SENT },
    { label: FREIGHT_STATUS_LABELS.DELIVERY_STARTED, value: FREIGHT_STATUS.DELIVERY_STARTED },
    { label: FREIGHT_STATUS_LABELS.DELIVERY_FINISHED, value: FREIGHT_STATUS.DELIVERY_FINISHED },
    { label: FREIGHT_STATUS_LABELS.UNLOADING_STARTED, value: FREIGHT_STATUS.UNLOADING_STARTED },
    { label: FREIGHT_STATUS_LABELS.UNLOADING_FINISHED, value: FREIGHT_STATUS.UNLOADING_FINISHED },
    { label: FREIGHT_STATUS_LABELS.INVOICE_COUPON_SENT, value: FREIGHT_STATUS.INVOICE_COUPON_SENT },
    { label: FREIGHT_STATUS_LABELS.INVOICE_COUPON_REFUSED, value: FREIGHT_STATUS.INVOICE_COUPON_REFUSED },
    { label: FREIGHT_STATUS_LABELS.FINISHED, value: SHORT_FREIGHT_STATUS.FINISHED },
  ],
  elegibleVehicles: [
    { label: 'Leves', value: ELEGIBLE_VEHICLES.Leves.join(',') },
    { label: 'Médios', value: ELEGIBLE_VEHICLES['Médios'].join(',') },
    { label: 'Pesados', value: ELEGIBLE_VEHICLES.Pesados.join(',') },
  ],
  type: [
    { label: 'Frete Simples', value: 'Unique' },
    { label: 'Frete em lote', value: 'Batch,BatchItem' },
  ],
  advanceRequestedDate: [
    { label: 'Solicitado', value: 'solicited' },
    { label: 'Não Solicitado', value: 'unsolicited' },
    { label: 'Realizado', value: 'paymentDone' },
  ],
  invoiceCoupon: [
    { label: 'Recebidos', value: 'invoiceCouponSent' },
    { label: 'Recusados', value: 'invoiceCouponRefused' },
  ],
};

export const FILTER_LABEL_ORDER = {
  status: [
    { label: 'Ordem Recebida', value: ORDER_STATUS.USER_ORDER_SENT },
    { label: 'Adiantamento Liberado', value: ORDER_STATUS.ADVANCE_PAYMENT },
    { label: 'Foto Solicitada', value: ORDER_STATUS.USER_ORDER_SENT_REFUSED },
    { label: 'Ordem Finalizada', value: ORDER_STATUS.FINISHED },
  ],
  paymentSuccessfulBalance: [
    { label: 'Pendente', value: 'paymentUnsuccessfulBalance' },
    { label: 'Quitado', value: 'paymentSuccessfulBalance' },
  ],
  advancedRequestedPaymentDate: [
    { label: 'Pendente', value: 'advancedUnrequestedPaymentDate' },
    { label: 'Quitado', value: 'advancedRequestedPaymentDate' },
  ],
};

export const FILTER_LABEL_OCCURRENCE = {
  category: {
    COMPLAINT: 'Reclamação',
    COMPLIMENT: 'Elogio',
    OTHERS: 'Outros',
    FREIGHT_CANCELLATION: 'Cancelamento de Frete',
    SCHEDULE_CHANGE: 'Alteração no cronograma',
    GENERAL_CHANGES: 'Alterações gerais',
    VEHICLE_PROBLEM: 'Problemas no veículo',
    ROUTE_PROBLEM: 'Problemas no trajeto',
    ROUTE_CHANGE: 'Alteração de rota'
  },
  status: {
    RESOLVED: 'Resolvido',
    UNRESOLVED: 'Não Resolvido',
  },
};

export const MASK = {
  cellPhone: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  cnpj: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  conta: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  agencia: [/\d/, /\d/, /\d/, /\d/, '-', /\d/],
  pis: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/],
};

export const BRAZILIAN_STATES = [
  { uf: 'AC', name: 'Acre' },
  { uf: 'AL', name: 'Alagoas' },
  { uf: 'AP', name: 'Amapá' },
  { uf: 'AM', name: 'Amazonas' },
  { uf: 'BA', name: 'Bahia' },
  { uf: 'CE', name: 'Ceará' },
  { uf: 'DF', name: 'Distrito Federal' },
  { uf: 'ES', name: 'Espírito Santo' },
  { uf: 'GO', name: 'Goiás' },
  { uf: 'MA', name: 'Maranhão' },
  { uf: 'MT', name: 'Mato Grosso' },
  { uf: 'MS', name: 'Mato Grosso do Sul' },
  { uf: 'MG', name: 'Minas Gerais' },
  { uf: 'PA', name: 'Pará' },
  { uf: 'PB', name: 'Paraíba' },
  { uf: 'PR', name: 'Paraná' },
  { uf: 'PE', name: 'Pernambuco' },
  { uf: 'PI', name: 'Piauí' },
  { uf: 'RJ', name: 'Rio de Janeiro' },
  { uf: 'RN', name: 'Rio Grande do Norte' },
  { uf: 'RS', name: 'Rio Grande do Sul' },
  { uf: 'RO', name: 'Rondônia' },
  { uf: 'RR', name: 'Roraima' },
  { uf: 'SC', name: 'Santa Catarina' },
  { uf: 'SP', name: 'São Paulo' },
  { uf: 'SE', name: 'Sergipe' },
  { uf: 'TO', name: 'Tocantins' },
];

/**
 * Fn para montar o btn de amostragem de documento
 * Se for pdf, deve ser um link que abre em outra aba
 * Senão renderiza o btn normal que apresenta a modal com a opção de zoom
 */
export const allowedExtensions = ['jpeg', 'jpg', 'png'];

/**
 * lista de MIME Types para fazer o upload de documentos na Mesa
 */
export const allowedMimeExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

export const formatPis = (pis) => {
  pis = pis.replace(/\D/g, '');
  pis = pis.replace(/^(\d{3})(\d)/, '$1.$2');
  pis = pis.replace(/^(\d{3})\.(\d{5})(\d)/, '$1.$2.$3');
  pis = pis.replace(/(\d{3})\.(\d{5})\.(\d{2})(\d)/, '$1.$2.$3.$4');
  return pis;
};

export const validarPis = (pis) => {
  const multiplicadorBase = '3298765432';
  let total = 0;
  let resto = 0;
  let multiplicando = 0;
  let multiplicador = 0;
  let digito = 99;

  // Retira a mascara
  const numeroPIS = pis.replace(/[^\d]+/g, '');
  if (
    numeroPIS.length !== 11 ||
    numeroPIS === '00000000000' ||
    numeroPIS === '11111111111' ||
    numeroPIS === '22222222222' ||
    numeroPIS === '33333333333' ||
    numeroPIS === '44444444444' ||
    numeroPIS === '55555555555' ||
    numeroPIS === '66666666666' ||
    numeroPIS === '77777777777' ||
    numeroPIS === '88888888888' ||
    numeroPIS === '99999999999'
  ) {
    return false;
  }
  for (let i = 0; i < 10; i++) {
    multiplicando = parseInt(numeroPIS.substring(i, i + 1));
    multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
    total += multiplicando * multiplicador;
  }
  resto = 11 - (total % 11);
  resto = resto === 10 || resto === 11 ? 0 : resto;
  digito = parseInt(`${numeroPIS.charAt(10)}`);
  return resto === digito;
};