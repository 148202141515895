import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  dateNow,
  downloadFromBuffer,
  formatDate,
  formatHour,
  getFromAWS,
  getPdfFromAWS,
  hasImageExtension,
  isDocumentValid,
  sendToAWS,
  validateDocumentType
} from '../../utils/utils';
import { DndProvider } from 'react-dnd';
import ReactImageMagnify from 'react-image-magnify';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FREIGHT_STATUS } from '../../constants';
import { enqueueSnackbar } from '../../actions/notifications';
import Image from '../Image';
import Modal from '../Modal';
import styles from './styles';
import PhotosModal from '../ModalPhotos';
import PhotosToPdf from '../PhotosToPdf/index.tsx';
import ModalConfirm from '../ModalConfirmation';

const Steps = ({ classes, freight, enqueueSnackbar, nextStep, update, isLoading, boardUser }) => {
  const [modalOpen, setModalOpen] = useState({
    riskManagement: false,
    invoiceCoupon: false,
    advancedRequestedPaymentDate: false,
    fiscalDocuments: false,
    collectionOrder: false,
    paymentSuccessfulBalance: false,
  });
  const [imgUploaded, setImgUploaded] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(undefined);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [invoiceCouponPhotos, setInvoiceCouponPhotos] = useState(freight.invoiceCouponPhotos || []);
  const [invoiceCouponDocs, setInvoiceCouponDocs] = useState(freight.invoiceCouponDocs || []);
  const [municipalFreight, setMunicipalFreight] = useState(freight.municipalFreight || {});
  const [isRejecting, setIsRejecting] = useState(false);
  const [showFinishedButton, setShowFinishedButton] = useState(false);
  const [showInvoiceCouponSentButton, setShowInvoiceCouponSentButton] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [rejectionIndex, setRejectionIndex] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const status = [
    'WAITING',
    'DRIVER_SELECTED',
    'APPROVED',
    'PICKUP_ORDER_SENT',
    'DRIVER_ARRIVED',
    'LOADING_STARTED',
    'LOADING_FINISHED',
    'ADVANCE_REQUESTED',
    'INVOICE_SENT',
    'DELIVERY_STARTED',
    'DELIVERY_FINISHED',
    'UNLOADING_STARTED',
    'UNLOADING_FINISHED',
    'INVOICE_COUPON_SENT',
    'INVOICE_COUPON_REFUSED',
    'FINISHED',
  ];

  const refConfirmButton = useRef();
  const refDriveSelectedButton = useRef();
  const refApprovedButton = useRef();
  const refPickupOrderPhotoButton = useRef();
  const refLoadingStartedButton = useRef();
  const refLoadingFinishButton = useRef();
  const refUnloadingStartedButton = useRef();
  const refUnloadingFinishedButton = useRef();
  const refInvoiceCouponButton = useRef();
  const refFinishedButton = useRef();

  useEffect(() => {
    updateCurrentIndex();
    switch (freight.status) {
      case 'DRIVER_SELECTED':
        if (refConfirmButton.current) refConfirmButton.current.scrollIntoView();
        break;
      case 'APPROVED':
        if (refDriveSelectedButton.current) refDriveSelectedButton.current.scrollIntoView();
        break;
      case 'PICKUP_ORDER_SENT':
        if (refApprovedButton.current) refApprovedButton.current.scrollIntoView();
        break;
      case 'LOADING_STARTED':
        if (refPickupOrderPhotoButton.current) refPickupOrderPhotoButton.current.scrollIntoView();
        break;
      case 'LOADING_FINISHED':
        if (refLoadingStartedButton.current) refLoadingStartedButton.current.scrollIntoView();
        break;
      case 'INVOICE_SENT':
        if (refLoadingFinishButton.current) refLoadingFinishButton.current.scrollIntoView();
        break;
      case 'UNLOADING_STARTED':
        if (refUnloadingStartedButton.current) refUnloadingStartedButton.current.scrollIntoView();
        break;
      case 'UNLOADING_FINISHED':
        if (refUnloadingFinishedButton.current) refUnloadingFinishedButton.current.scrollIntoView();
        break;
      case 'INVOICE_COUPON_SENT':
        if (refInvoiceCouponButton.current) refInvoiceCouponButton.current.scrollIntoView();
        break;
      case 'INVOICE_COUPON_REFUSED':
        if (refInvoiceCouponButton.current) refInvoiceCouponButton.current.scrollIntoView();
        break;
      case 'FINISHED':
        if (refFinishedButton.current) refFinishedButton.current.scrollIntoView();
        break;
      default:
        break;
    }  // eslint-disable-next-line
  }, [freight.status]);

  useEffect(() => {
    let isMounted = true;
    const updatePhotosAndDocs = () => {
      if (isMounted) {
        if ((freight.status === 'UNLOADING_STARTED' ||
          freight.status === 'UNLOADING_FINISHED') &&
          ((invoiceCouponPhotos && invoiceCouponPhotos.length > 0) ||
            (invoiceCouponDocs && invoiceCouponDocs.length > 0)
          )
        ) {
          setShowInvoiceCouponSentButton(true);
        } else {
          setShowInvoiceCouponSentButton(false);
        }
        if ((freight.status === 'INVOICE_COUPON_SENT' || freight.status === 'INVOICE_COUPON_REFUSED') &&
          (allPhotosApproved() || allDocsApproved())) {
          setShowFinishedButton(true);
        } else {
          setShowFinishedButton(false);
        }
      }
    };
    updatePhotosAndDocs();
    return () => {
      isMounted = false;
    }; // eslint-disable-next-line
  }, [freight.status, freight.invoiceCouponPhotos, freight.invoiceCouponDocs, freight.municipalFreight]);

  const updateCurrentIndex = () => {
    const newCurrentIndex = status.indexOf(freight.status);
    if (newCurrentIndex !== currentIndex) {
      setCurrentIndex(newCurrentIndex);
    }
  };

  const checkIndex = (statusValue) =>
    freight.shortStatusHistory.findIndex((f) => f.status === statusValue);

  const setImage = (event) => {
    const file = event.target.files[0];
    setImgUploaded(file || null);
  };

  const setImageAndUpload = (event, type) => {
    const file = event.target.files[0];
    if (!file) return;
    updatePhoto(file, type);
  };

  const updatePhoto = async (photo, type) => {
    if (!isDocumentValid(photo)) {
      setImgUploaded(null);
      enqueueSnackbar({
        message: 'Os formatos permitidos são: PNG, JPG e PDF.',
        options: { variant: 'error' },
      });
      return false;
    }

    try {
      const response = await validateDocumentType(photo);
      enqueueSnackbar({ message: 'Atualizando frete...' });

      if (response.data && response.data.signedUrl) {
        const { status } = await sendToAWS(response.data.signedUrl, photo);
        if (status !== 200) throw new Error('send photo to AWS error, check cloudwatch');
      } else {
        console.error('Sem url assinada');
      }

      const photoUrl = response.data.imageUrl;

      switch (type) {
        case 'pickupOrderPhoto':
          await nextStep(freight.status, freight.id, { pickupOrderPhoto: photoUrl }, true);
          break;
        case 'invoicePhoto':
          await nextStep(freight.status, freight.id, { invoicePhoto: photoUrl }, true);
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar({
        message: 'Não foi possível enviar a foto. Tente novamente.',
        options: { variant: 'error' },
      });
    }
  };

  const upload = async (nextStatus, freightId) => {
    if (!isDocumentValid(imgUploaded)) {
      setImgUploaded(null);
      enqueueSnackbar({
        message: 'Os formatos permitidos são: PNG, JPG e PDF.',
        options: { variant: 'error' },
      });
      return false;
    }

    enqueueSnackbar({ message: 'Enviando arquivo', options: { variant: 'info' } });

    try {
      const response = await validateDocumentType(imgUploaded);

      if (response.data && response.data.signedUrl) {
        await sendToAWS(response.data.signedUrl, imgUploaded);
      } else {
        console.error('Sem url assinada');
      }

      const photoUrl = response.data.imageUrl;

      switch (nextStatus) {
        case 'LOADING_FINISHED':
          await nextStep(nextStatus, freightId, { pickupOrderPhoto: photoUrl });
          setImgUploaded(null);
          break;
        case 'UNLOADING_STARTED':
          await nextStep(nextStatus, freightId, { invoicePhoto: photoUrl });
          setImgUploaded(null);
          break;
        default:
          break;
      }

      enqueueSnackbar({ message: 'Enviado com sucesso', options: { variant: 'success' } });
    } catch (err) {
      setImgUploaded(null);
      enqueueSnackbar({
        message: 'Não foi possível enviar a foto. Tente novamente.',
        options: { variant: 'error' },
      });
    }
  };

  const modalHandler = (type, index) => {
    setModalOpen((prev) => ({ ...prev, [type]: !prev[type] }));
    setPhotoIndex(index);
  };

  const allPhotosApproved = () => {
    return Array.isArray(invoiceCouponPhotos) && invoiceCouponPhotos.length > 0 && invoiceCouponPhotos.every((photo) =>
      typeof photo === 'string' ? false : photo.status === 'APPROVED'
    );
  };

  const allDocsApproved = () => {
    return Array.isArray(invoiceCouponDocs) && invoiceCouponDocs.length > 0 && invoiceCouponDocs.every((doc) =>
      typeof doc === 'string' ? false : doc.status === 'APPROVED'
    );
  };

  const handleUpdateFreight = async (data) => {
    const { order } = data;
    if (order.pdfUrl) {
      await update('pdfUrl', order.pdfUrl);
    }
    if (order.userImages) {
      await update('invoiceCouponPhotos', order.userImages);
      setInvoiceCouponPhotos(order.userImages);
    }
    enqueueSnackbar({ message: 'Fotos atualizadas com sucesso', options: { variant: 'success' } });
  };

  const handleApprovePhoto = (index) => {
    const updatedPhotos = [...invoiceCouponPhotos];
    updatedPhotos[index] = { ...updatedPhotos[index], status: 'APPROVED', message: '' };
    setInvoiceCouponPhotos(updatedPhotos);
    update('invoiceCouponPhotos', updatedPhotos);
    if (allPhotosApproved()) {
      nextStep('INVOICE_COUPON_SENT', freight.id, {});
    }
  };

  const handleRejectPhoto = (index, message) => {
    const updatedPhotos = [...invoiceCouponPhotos];
    updatedPhotos[index] = { ...updatedPhotos[index], status: 'DENIED', message };
    setInvoiceCouponPhotos(updatedPhotos);
    update('invoiceCouponPhotos', updatedPhotos);
    if (freight.status === FREIGHT_STATUS.INVOICE_COUPON_SENT) {
      nextStep('INVOICE_COUPON_REFUSED', freight.id, {});
    }
  };

  const handleApproveDoc = (index) => {
    const updatedDocs = [...freight.invoiceCouponDocs];
    updatedDocs[index] = {
      ...updatedDocs[index],
      status: 'APPROVED',
      sender: `${boardUser.name}`,
      message: `Aprovado em ${dateNow()}`
    };

    update('invoiceCouponDocs', updatedDocs);
    setInvoiceCouponDocs(updatedDocs);
    const singleDocApproved = updatedDocs.length === 1 && updatedDocs[0].status === 'APPROVED';

    if (singleDocApproved || allDocsApproved()) {
      nextStep('INVOICE_COUPON_SENT', freight.id, {});
    }

    enqueueSnackbar({ message: 'PDF aprovado com sucesso', options: { variant: 'success' } });
  };

  const handleRejectDoc = (index) => {
    setRejectionIndex(index);
    setIsRejecting(true);
  };

  const handleConfirmRejection = () => {
    if (rejectReason.trim()) {
      const updatedDocs = [...freight.invoiceCouponDocs];
      updatedDocs[rejectionIndex] = {
        ...updatedDocs[rejectionIndex],
        status: 'DENIED', sender: `${boardUser.name}`, message: rejectReason
      };
      update('invoiceCouponDocs', updatedDocs);
      setInvoiceCouponDocs(updatedDocs);
      if (freight.status !== FREIGHT_STATUS.INVOICE_COUPON_REFUSED) {
        nextStep('INVOICE_COUPON_REFUSED', freight.id, {});
      }
      enqueueSnackbar({ message: 'PDF reprovado com sucesso', options: { variant: 'success' } });

      setIsRejecting(false);
      setRejectReason('');
      setRejectionIndex(null);
    } else {
      enqueueSnackbar({ message: 'Por favor, descreva o motivo da reprovação', options: { variant: 'warning' } });
    }
  };

  const download = async (imageUrl) => {
    try {
      enqueueSnackbar({
        message: 'Iniciando download...',
        options: { variant: 'info' },
      });

      const response = await getFromAWS(imageUrl.split('/').pop());

      enqueueSnackbar({
        message: 'Download concluído com sucesso!',
        options: { variant: 'success' },
      });

      downloadFromBuffer(response.data.body, imageUrl, response.data.headers["Content-Type"]);
    } catch (error) {
      console.error('Erro ao fazer download:', error);

      enqueueSnackbar({
        message: 'Erro ao fazer download. Tente novamente.',
        options: { variant: 'error' },
      });
    }
  };

  const handleDownloadDocument = async (imageUrl) => {
    try {
      const fileNameAux = imageUrl.split('/');
      const fileName = fileNameAux[fileNameAux.length - 1];
      const response = await getPdfFromAWS(fileName);
      const contentType = response.data.headers['Content-Type'];
      if (response.status === 200) {
        const base64 = response.data.body;
        downloadFromBuffer(base64, fileName, contentType);
        enqueueSnackbar({
          message: 'Download concluído com sucesso!',
          options: { variant: 'success' },
        });
      } else {
        throw new Error('Falha ao obter o documento do servidor.');
      }
    } catch (err) {
      enqueueSnackbar({
        message: 'Erro ao fazer download. Tente novamente.',
        options: { variant: 'error' },
      });
    }
  };

  const municipalFreightHandler = (boardUserName) => {
    try {
      const data = {
        boardUserName: boardUserName,
        updatedAt: dateNow(),
        municipalFreight: true,
      };
      update('municipalFreight', data);
      nextStep('UNLOADING_STARTED', freight.id, {});
      setMunicipalFreight(data)
      enqueueSnackbar({
        message: 'Frete municipal informado com sucesso!',
        options: { variant: 'success' },
      });
    } catch (error) {
      enqueueSnackbar({
        message: `Erro ao informar frete municipal. Tente novamente. Erro: ${error.message}`,
        options: { variant: 'error' },
      });
    }
  }

  const handleViewPdf = (pdfUrl) => {
    setSelectedPdf(pdfUrl);
  };

  const mappedShortHistory = {};
  Object.values(freight.shortStatusHistory).forEach(
    (freight) =>
    (mappedShortHistory[freight.status] = {
      date: freight.date,
      city: freight.city,
      state: freight.state,
    })
  );

  return (
    <>
      {modalOpen.invoiceCoupon ? (
        <PhotosModal
          open={modalOpen.invoiceCoupon}
          photos={invoiceCouponPhotos}
          initialIndex={photoIndex}
          onApprovePhoto={handleApprovePhoto}
          onRejectPhoto={handleRejectPhoto}
          onDownloadPhoto={download}
          onClose={() =>
            setModalOpen({ ...modalOpen, invoiceCoupon: false })}
          status={freight.status}
        />
      ) : null}

      {modalOpen.collectionOrder && (
        <Modal modalHandler={() => modalHandler('collectionOrder')}>
          <div className={classes.modal}>
            <div className={classes.modalHeader}>
              <p>COMPROVANTE DE ORDEM DE COLETA</p>
            </div>
            <DialogContent className={classes.dialogContent}>
              <ReactImageMagnify
                className={classes.imageContainer}
                smallImage={{
                  isFluidWidth: true,
                  src: freight.pickupOrderPhoto,
                  width: 428,
                  height: 300,
                }}
                largeImage={{
                  src: freight.pickupOrderPhoto,
                  width: 1200,
                  height: 1800,
                }}
                enlargedImagePosition="over"
                style={{ width: '100%', height: '300px' }}
                imageStyle={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  margin: '0 auto',
                }}
              />
            </DialogContent>
            <div className={classes.modalFooter}>
              <Button
                className={[classes.btn, classes.btnBlue].join(' ')}
                size="small"
                variant="contained"
                onClick={() => setModalOpen({ ...modalOpen, collectionOrder: false })}
              >
                FECHAR
              </Button>
            </div>{' '}
          </div>
        </Modal>
      )}

      {modalOpen.fiscalDocuments && (
        <Modal modalHandler={() => modalHandler('fiscalDocuments')}>
          <div className={classes.modal}>
            <div className={classes.modalHeader}>
              <p>COMPROVANTE DE CTe/MDFe</p>
            </div>
            <DialogContent className={classes.dialogContent}>
              <ReactImageMagnify
                className={classes.imageContainer}
                smallImage={{
                  isFluidWidth: true,
                  src: freight.invoicePhoto,
                  width: 428,
                  height: 300,
                }}
                largeImage={{
                  src: freight.invoicePhoto,
                  width: 1200,
                  height: 1800,
                }}
                enlargedImagePosition="over"
                style={{ width: '100%', height: '300px' }}
                imageStyle={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  margin: '0 auto',
                }}
              />
            </DialogContent>
            <div className={classes.modalFooter}>
              <Button
                className={[classes.btn, classes.btnBlue].join(' ')}
                size="small"
                variant="contained"
                onClick={() => setModalOpen({ ...modalOpen, fiscalDocuments: false })}
              >
                FECHAR
              </Button>
            </div>{' '}
          </div>
        </Modal>
      )}

      {modalOpen.riskManagement && (
        <ModalConfirm
          open={modalOpen.riskManagement}
          title="Tem certeza?"
          message={"Você confirma que o motorista foi liberado pelo gerenciamento de risco e está apto para a operação?"}
          handleCancel={() => modalHandler('riskManagement')}
          handleConfirm={() => {
            modalHandler('riskManagement');
            nextStep('APPROVED', freight.id, { userId: freight.driver });
          }}
        />
      )}

      {modalOpen.advancedRequestedPaymentDate && (
        <ModalConfirm
          open={modalOpen.advancedRequestedPaymentDate}
          title="Tem certeza?"
          message="Você confirma o adiantamento de pagamento?"
          handleCancel={() => modalHandler('advancedRequestedPaymentDate')}
          handleConfirm={() => {
            modalHandler('advancedRequestedPaymentDate');
            update('advancedRequestedPaymentDate');
          }}
        />
      )}

      {modalOpen.paymentSuccessfulBalance && (
        <ModalConfirm
          open={modalOpen.paymentSuccessfulBalance}
          title="Tem certeza?"
          message="Você confirma o pagamento do saldo?"
          handleCancel={() => modalHandler('paymentSuccessfulBalance')}
          handleConfirm={() => {
            modalHandler('paymentSuccessfulBalance');
            update('paymentSuccessfulBalance');
          }}
        />
      )}

      {selectedPdf && (
        <Modal
          open={Boolean(selectedPdf)}
          onClose={() => setSelectedPdf(null)}
          className={classes.modal}
        >
          <div className={classes.modalContent}>
            <Typography variant="h6" gutterBottom>
              Visualização do Documento
            </Typography>
            <iframe src={selectedPdf} className={classes.modalIframe} title="Document Preview" />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSelectedPdf(null)}
              className={classes.closeButton}
            >
              Fechar
            </Button>
          </div>
        </Modal>
      )}

      {freight.id ? (
        <div className={classes.mainContent}>
          <div className={classes.line}>
            <div className={classes.timeStatus}>
              {mappedShortHistory.WAITING && (
                <>
                  <p>{formatDate(mappedShortHistory.WAITING.date)}</p>
                  <p>{formatHour(mappedShortHistory.WAITING.date)}</p>
                  <p>{mappedShortHistory.WAITING.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content} ref={refConfirmButton}>
              <p>
                {freight && freight.requestingUsers[freight.driver].requestStatus === 'TARGETED'
                  ? 'Frete direcionado para motorista.'
                  : 'Frete solicitado pelo motorista.'}
              </p>
            </div>
          </div>

          <div className={classes.line} ref={refDriveSelectedButton}>
            {checkIndex('DRIVER_SELECTED') <= currentIndex && checkIndex('DRIVER_SELECTED') !== -1 ? null : (
              <div className={classes.disabled} />
            )}
            <div className={classes.timeStatus}>
              {mappedShortHistory.DRIVER_SELECTED && (
                <>
                  <p>{formatDate(mappedShortHistory.DRIVER_SELECTED.date)}</p>
                  <p>{formatHour(mappedShortHistory.DRIVER_SELECTED.date)}</p>
                  <p>{mappedShortHistory.DRIVER_SELECTED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Confirmar frete.</p>
              <>
                {isLoading ? (
                  <CircularProgress size={24} style={{ padding: '4px' }} />
                ) : (
                  <button
                    disabled={isLoading}
                    className={
                      freight.status === 'DRIVER_SELECTED'
                        ? [classes.btn, classes.btnBlue].join(' ')
                        : [classes.btn, classes.btnDisabled].join(' ')
                    }
                    onClick={() => modalHandler('riskManagement')}
                  >
                    CONFIRMAR
                  </button>
                )}
              </>
            </div>
          </div>

          <div className={classes.line} ref={refApprovedButton}>
            {checkIndex('APPROVED') <= currentIndex && checkIndex('APPROVED') !== -1 ? null : (
              <div className={classes.disabled} />
            )}
            <div className={classes.timeStatus}>
              {mappedShortHistory.APPROVED && (
                <>
                  <p>{formatDate(mappedShortHistory.APPROVED.date)}</p>
                  <p>{formatHour(mappedShortHistory.APPROVED.date)}</p>
                  <p>{mappedShortHistory.APPROVED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Emissão da ordem de coleta.</p>
              <div>
                {!freight.pickupOrderPhoto && (
                  <>
                    {isLoading ? (
                      <CircularProgress size={24} style={{ padding: '4px' }} />
                    ) : (
                      <>
                        <button
                          disabled={isLoading}
                          className={
                            freight.status === 'APPROVED' && !!imgUploaded
                              ? [classes.btn, classes.btnGreen].join(' ')
                              : [classes.btn, classes.btnDisabled].join(' ')
                          }
                          onClick={() => upload('LOADING_FINISHED', freight.id)}
                        >
                          EMITIR
                        </button>
                        <label
                          htmlFor="PICKUP_ORDER_SENT_INPUT"
                          className={
                            freight.status === 'APPROVED'
                              ? [classes.btn, classes.btnBlue].join(' ')
                              : [classes.btn, classes.btnDisabled].join(' ')
                          }
                        >
                          SELECIONAR ARQUIVO
                        </label>
                        <input type="file" id="PICKUP_ORDER_SENT_INPUT" onChange={setImage} />
                      </>
                    )}
                  </>
                )}

                {freight.pickupOrderPhoto ? (
                  <>
                    {hasImageExtension(freight.pickupOrderPhoto) ? (
                      <button
                        disabled={isLoading}
                        className={[classes.btn, classes.btnBlue].join(' ')}
                        onClick={() => modalHandler('collectionOrder')}
                      >
                        VER
                      </button>
                    ) : (
                      <a
                        href={freight.pickupOrderPhoto}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={[classes.btn, classes.btnBlue].join(' ')}
                      >
                        VER
                      </a>
                    )}
                    <button
                      disabled={isLoading}
                      href={freight.pickupOrderPhoto}
                      className={[classes.btn, classes.btnBlue].join(' ')}
                      onClick={() => download(freight.pickupOrderPhoto)}
                    >
                      Baixar documento
                    </button>
                    {freight.status !== 'FINISHED' && (
                      <>
                        <label
                          htmlFor="change_pickupOrder"
                          className={[classes.btn, classes.btnBlue].join(' ')}
                        >
                          TROCAR ARQUIVO
                        </label>
                        <input
                          type="file"
                          id="change_pickupOrder"
                          onChange={(e) => setImageAndUpload(e, 'pickupOrderPhoto')}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className={classes.line}>
            {checkIndex('PICKUP_ORDER_SENT') <= currentIndex && checkIndex('PICKUP_ORDER_SENT') !== -1 ? null : (
              <div className={classes.disabled} />
            )}
            <div className={classes.timeStatus}>
              {mappedShortHistory.PICKUP_ORDER_SENT && (
                <>
                  <p>{formatDate(mappedShortHistory.PICKUP_ORDER_SENT.date)}</p>
                  <p>{formatHour(mappedShortHistory.PICKUP_ORDER_SENT.date)}</p>
                  <p>{mappedShortHistory.PICKUP_ORDER_SENT.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Motorista está se dirigindo ao local de coleta.</p>
            </div>
          </div>

          <div className={classes.line}>
            {checkIndex('DRIVER_ARRIVED') <= currentIndex && checkIndex('DRIVER_ARRIVED') !== -1 ? null : (
              <div className={classes.disabled} />
            )}
            <div className={classes.timeStatus}>
              {mappedShortHistory.DRIVER_ARRIVED && (
                <>
                  <p>{formatDate(mappedShortHistory.DRIVER_ARRIVED.date)}</p>
                  <p>{formatHour(mappedShortHistory.DRIVER_ARRIVED.date)}</p>
                  <p>{mappedShortHistory.DRIVER_ARRIVED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Motorista chegou ao local.</p>
            </div>
          </div>

          <div className={classes.line} ref={refLoadingStartedButton}>
            {checkIndex('LOADING_STARTED') <= currentIndex && checkIndex('LOADING_STARTED') !== -1 ? null : (
              <div className={classes.disabled} />
            )}
            <div className={classes.timeStatus}>
              {mappedShortHistory.LOADING_STARTED && (
                <>
                  <p>{formatDate(mappedShortHistory.LOADING_STARTED.date)}</p>
                  <p>{formatHour(mappedShortHistory.LOADING_STARTED.date)}</p>
                  <p>{mappedShortHistory.LOADING_STARTED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Carregamento iniciado pelo motorista.</p>
            </div>
          </div>

          <div className={classes.line}>
            {checkIndex('LOADING_FINISHED') !== -1 ? null : (
              <div className={classes.disabled} />
            )}
            <div className={classes.timeStatus}>
              {mappedShortHistory.LOADING_FINISHED && (
                <>
                  <p>{formatDate(mappedShortHistory.LOADING_FINISHED.date)}</p>
                  <p>{formatHour(mappedShortHistory.LOADING_FINISHED.date)}</p>
                  <p>{mappedShortHistory.LOADING_FINISHED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Carregamento finalizado pelo motorista.</p>
            </div>
          </div>

          <div className={classes.line} ref={refLoadingFinishButton}>
            {checkIndex('LOADING_FINISHED') !== -1 ? null : (
              <div className={classes.disabled} />
            )}
            <div className={classes.timeStatus}>
              {mappedShortHistory.LOADING_FINISHED && (
                <>
                  <p>{formatDate(mappedShortHistory.LOADING_FINISHED.date)}</p>
                  <p>{formatHour(mappedShortHistory.LOADING_FINISHED.date)}</p>
                  <p>{mappedShortHistory.LOADING_FINISHED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Enviar Documentação (CTe/ MDFe)</p>
              <div>
                {!freight.invoicePhoto && (
                  <>
                    {isLoading ? (
                      <CircularProgress size={24} style={{ padding: '4px' }} />
                    ) : (
                      <>
                        <button
                          disabled={isLoading}
                          className={
                            freight.status === 'LOADING_FINISHED' && !!imgUploaded
                              ? [classes.btn, classes.btnGreen].join(' ')
                              : [classes.btn, classes.btnDisabled].join(' ')
                          }
                          onClick={() => upload('UNLOADING_STARTED', freight.id)}
                        >
                          ENVIAR
                        </button>
                        <label
                          htmlFor="LOADING_FINISHED_INPUT"
                          className={
                            freight.status === 'LOADING_FINISHED'
                              ? [classes.btn, classes.btnBlue].join(' ')
                              : [classes.btn, classes.btnDisabled].join(' ')
                          }
                        >
                          SELECIONAR ARQUIVO
                        </label>
                        <input type="file" id="LOADING_FINISHED_INPUT" onChange={setImage} />
                        <button
                          className={
                            freight.status === 'LOADING_FINISHED'
                              ? [classes.btn, classes.btnBlue].join(' ')
                              : [classes.btn, classes.btnDisabled].join(' ')
                          }
                          onClick={() => municipalFreightHandler(boardUser.name)}
                        >
                          FRETE MUNICIPAL SEM CTe/MDFe
                        </button>
                        {municipalFreight && municipalFreight.municipalFreight === true && (
                          <p>{`Frete municipal informado por ${municipalFreight?.boardUserName}`}</p>
                        )}
                      </>
                    )}
                  </>
                )}

                {freight.invoicePhoto ? (
                  <>
                    {hasImageExtension(freight.invoicePhoto) ? (
                      <button
                        disabled={isLoading}
                        className={[classes.btn, classes.btnBlue].join(' ')}
                        onClick={() => modalHandler('fiscalDocuments')}
                      >
                        VER
                      </button>
                    ) : (
                      <a
                        href={freight.invoicePhoto}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={[classes.btn, classes.btnBlue].join(' ')}
                      >
                        VER
                      </a>
                    )}
                    <button
                      disabled={isLoading}
                      href={freight.invoicePhoto}
                      className={[classes.btn, classes.btnBlue].join(' ')}
                      onClick={() => download(freight.invoicePhoto)}
                    >
                      Baixar documento
                    </button>
                    {freight.status !== 'FINISHED' && (
                      <>
                        <label
                          htmlFor="change_cte"
                          className={[classes.btn, classes.btnBlue].join(' ')}
                        >
                          TROCAR ARQUIVO
                        </label>
                        <input
                          type="file"
                          id="change_cte"
                          onChange={(e) => setImageAndUpload(e, 'invoicePhoto')}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {freight.paymentAdvance && freight.paymentAdvance > 0 ? (
                  !freight.advanceRequestedDate ? (
                    <>
                      {isLoading ? (
                        <CircularProgress size={24} style={{ padding: '4px' }} />
                      ) : (
                        <button
                          disabled={isLoading}
                          className={
                            freight.advanceRequestedDate
                              ? [classes.btn, classes.btnDisabled].join(' ')
                              : [classes.btn, classes.btnBlue].join(' ')
                          }
                          onClick={() => update('advanceRequestedDate')}
                        >
                          SOLICITAR ADIANTAMENTO
                        </button>
                      )}
                    </>
                  ) : !freight.advancedRequestedPaymentDate ? (
                    <>
                      {isLoading ? (
                        <CircularProgress size={24} style={{ padding: '4px' }} />
                      ) : (
                        <button
                          disabled={isLoading}
                          className={
                            freight.advancedRequestedPaymentDate
                              ? [classes.btn, classes.btnDisabled].join(' ')
                              : [classes.btn, classes.btnBlue].join(' ')
                          }
                          onClick={() => modalHandler('advancedRequestedPaymentDate')}
                        >
                          Confirmar Pagamento de Adiantamento
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      disabled={isLoading}
                      className={
                        freight.advancedRequestedPaymentDate
                          ? [classes.btn, classes.btnDisabled].join(' ')
                          : [classes.btn, classes.btnBlue].join(' ')
                      }
                    >
                      ADIANTAMENTO PAGO
                    </button>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className={classes.line}>
            {checkIndex('DELIVERY_STARTED') !== -1 ? null : (
              <div className={classes.disabled} />
            )}
            <div className={classes.timeStatus}>
              {mappedShortHistory.DELIVERY_STARTED && (
                <>
                  <p>{formatDate(mappedShortHistory.DELIVERY_STARTED.date)}</p>
                  <p>{formatHour(mappedShortHistory.DELIVERY_STARTED.date)}</p>
                  <p>{mappedShortHistory.DELIVERY_STARTED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Motorista iniciou a viagem.</p>
            </div>
          </div>

          <div className={classes.line}>
            {checkIndex('DELIVERY_FINISHED') !== -1 ? null : (
              <div className={classes.disabled} />
            )}
            <div className={classes.timeStatus}>
              {mappedShortHistory.DELIVERY_FINISHED && (
                <>
                  <p>{formatDate(mappedShortHistory.DELIVERY_FINISHED.date)}</p>
                  <p>{formatHour(mappedShortHistory.DELIVERY_FINISHED.date)}</p>
                  <p>{mappedShortHistory.DELIVERY_FINISHED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Motorista chegou ao local de destino.</p>
            </div>
          </div>

          <div className={classes.line} ref={refUnloadingStartedButton}>
            {mappedShortHistory.UNLOADING_STARTED ? null : <div className={classes.disabled} />}
            <div className={classes.timeStatus}>
              {mappedShortHistory.UNLOADING_STARTED && (
                <>
                  <p>{formatDate(mappedShortHistory.UNLOADING_STARTED.date)}</p>
                  <p>{formatHour(mappedShortHistory.UNLOADING_STARTED.date)}</p>
                  <p>{mappedShortHistory.UNLOADING_STARTED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Descarregamento iniciado pelo motorista.</p>
              <div className={classes.invoiceCouponButtons}>
                {showInvoiceCouponSentButton && (
                  <Button
                    className={[classes.btn, classes.btnBlue].join(' ')}
                    size="small"
                    variant="contained"
                    onClick={() => nextStep('INVOICE_COUPON_SENT', freight.id, {})}
                  >
                    AVANÇAR PARA COMPROVANTES RECEBIDOS
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className={classes.line} ref={refUnloadingFinishedButton}>
            {mappedShortHistory.UNLOADING_STARTED ? null : <div className={classes.disabled} />}
            <div className={classes.timeStatus}>
              {mappedShortHistory.UNLOADING_FINISHED && (
                <>
                  <p>{formatDate(mappedShortHistory.UNLOADING_FINISHED.date)}</p>
                  <p>{formatHour(mappedShortHistory.UNLOADING_FINISHED.date)}</p>
                  <p>{mappedShortHistory.UNLOADING_FINISHED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Descarregamento finalizado pelo motorista.</p>
              {isLoadingPdf ? (
                <CircularProgress size={24} style={{ padding: '4px' }} />
              ) : (
                <>
                  {freight.invoiceCouponDocs && freight.invoiceCouponDocs.length > 0 ? (
                    <Paper elevation={3}>
                      <div className={classes.invoiceCouponDocs}>
                        {freight.invoiceCouponDocs.map((doc, index) => {
                          const statusLabel = {
                            APPROVED: 'Aprovado',
                            DENIED: 'Reprovado',
                            ALTERED: 'Alterado',
                            '': 'Aguardando Análise'
                          }[doc.status] || 'Aguardando Análise';

                          return (
                            <div key={index} className={classes.pdfContainer}>
                              <div className={classes.pdfTitle}>
                                <p style={{
                                  color:
                                    doc.status === "APPROVED" ? "green" :
                                      doc.status === "DENIED" ? "red" :
                                        doc.status === "ALTERED" ? "orange" : "blue"
                                }}>
                                  {`PDF ${index + 1} (${statusLabel})`}
                                </p>
                                {doc.status === 'DENIED' && doc.message && (
                                  <p className={classes.reproved}>Motivo: {doc.message}</p>
                                )}
                              </div>
                              <div className={classes.pdfButtons}>
                                <Button
                                  className={[classes.btn, classes.btnBlue].join(' ')}
                                  size="small"
                                  variant="contained"
                                  onClick={() => handleDownloadDocument(doc.imageUrl)}
                                >
                                  Baixar PDF
                                </Button>
                                <Button
                                  className={[classes.btn, classes.btnBlue].join(' ')}
                                  size="small"
                                  variant="contained"
                                  onClick={() => handleViewPdf(doc.imageUrl)}
                                >
                                  Visualizar PDF
                                </Button>
                                <>
                                  <Button
                                    className={[classes.btn, classes.btnGreen].join(' ')}
                                    size="small"
                                    variant="contained"
                                    onClick={() => handleApproveDoc(index)}
                                  >
                                    Aprovar
                                  </Button>
                                  <Button
                                    className={[classes.btn, classes.btnRed].join(' ')}
                                    size="small"
                                    variant="contained"
                                    onClick={() => handleRejectDoc(index)}
                                  >
                                    Reprovar
                                  </Button>
                                </>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Paper>
                  ) : (
                    <p>Nenhum PDF disponível</p>
                  )}
                </>
              )}
              {isRejecting && (
                <div className={classes.rejectSection}>
                  <input
                    type="text"
                    placeholder="Descreva o motivo"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    className={classes.rejectInput}
                  />
                  <Button
                    className={[classes.btn, classes.btnConfirm].join(' ')}
                    size="small"
                    variant="contained"
                    onClick={handleConfirmRejection}
                  >
                    Confirmar Reprovação
                  </Button>
                </div>
              )}
            </div>
          </div>


          <div className={classes.line} ref={refInvoiceCouponButton}>
            <div className={classes.timeStatus}>
              {mappedShortHistory.INVOICE_COUPON_SENT && (
                <>
                  <p>{formatDate(mappedShortHistory.INVOICE_COUPON_SENT.date)}</p>
                  <p>{formatHour(mappedShortHistory.INVOICE_COUPON_SENT.date)}</p>
                  <p>{mappedShortHistory.INVOICE_COUPON_SENT.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              {freight.status === 'FINISHED' ? (
                <p>Comprovantes de descarga aprovados.</p>
              ) : (
                <p>Fotos de comprovantes de descarga + RIC</p>
              )}
              <>
                {isLoading || isLoadingPdf ? (
                  <CircularProgress size={24} style={{ padding: '4px' }} />
                ) : (
                  <>
                    {(freight.status === 'INVOICE_COUPON_SENT' ||
                      freight.status === 'INVOICE_COUPON_REFUSED') ?
                      ((invoiceCouponPhotos && invoiceCouponPhotos.length > 0) ||
                        (invoiceCouponDocs && invoiceCouponDocs.length > 0)) && (
                        <Paper elevation={3}>
                          <DndProvider backend={HTML5Backend}>
                            <div className={classes.invoiceCouponPhotos}>
                              {invoiceCouponPhotos.map((photo, index) => (
                                <Image
                                  key={index}
                                  photo={{ ...photo, status: photo.status }}
                                  label={`Foto ${index + 1}`}
                                  onClick={() => modalHandler('invoiceCoupon', index)}
                                  draggable="true"
                                />
                              ))}
                            </div>
                            <PhotosToPdf
                              id={freight.id}
                              type={"freight"}
                              isLoading={isLoading}
                              pdfUrlOld={freight.pdfUrl}
                              onUpdate={handleUpdateFreight}
                              setIsLoadingPdf={setIsLoadingPdf}
                              isLoadingPdf={isLoadingPdf}
                            />
                            <div className={classes.invoiceCouponButtons}>
                              {(allPhotosApproved() || allDocsApproved() || showFinishedButton) && (
                                <Button
                                  className={[classes.btn, classes.btnBlue].join(' ')}
                                  size="small"
                                  variant="contained"
                                  onClick={() => nextStep('FINISHED', freight.id, {})}
                                >
                                  ATUALIZAR STATUS PARA FINALIZADO
                                </Button>
                              )}
                            </div>
                          </DndProvider>
                        </Paper>
                      ) : (
                        <Paper elevation={3}>
                          <DndProvider backend={HTML5Backend}>
                            <div className={classes.invoiceCouponPhotos}>
                              {invoiceCouponPhotos.map((photo, index) => (
                                <Image
                                  key={index}
                                  photo={{ ...photo, status: photo.status }}
                                  label={`Foto ${index + 1}`}
                                  onClick={() => modalHandler('invoiceCoupon', index)}
                                  draggable="true"
                                />
                              ))}
                            </div>
                            <PhotosToPdf
                              id={freight.id}
                              type={"freight"}
                              isLoading={isLoading}
                              pdfUrlOld={freight.pdfUrl}
                              onUpdate={handleUpdateFreight}
                              setIsLoadingPdf={setIsLoadingPdf}
                              isLoadingPdf={isLoadingPdf}
                            />
                          </DndProvider>
                        </Paper>
                      )}
                  </>
                )}
              </>
            </div>
          </div>

          <div className={classes.line} ref={refFinishedButton}>
            {mappedShortHistory.FINISHED ? null : <div className={classes.disabled} />}
            <div className={classes.timeStatus}>
              {mappedShortHistory.FINISHED && (
                <>
                  <p>{formatDate(mappedShortHistory.FINISHED.date)}</p>
                  <p>{formatHour(mappedShortHistory.FINISHED.date)}</p>
                  <p>{mappedShortHistory.FINISHED.city}</p>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.content}>
              <p>Frete Finalizado</p>

              {mappedShortHistory.FINISHED ? (
                !freight.paymentSuccessfulBalance ? (
                  <>
                    {isLoading ? (
                      <CircularProgress size={24} style={{ padding: '4px' }} />
                    ) : (
                      <button
                        disabled={isLoading}
                        className={[classes.btn, classes.btnBlue].join(' ')}
                        onClick={() => modalHandler('paymentSuccessfulBalance')}
                      >
                        Informar Pagamento de Saldo
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    {isLoading ? (
                      <CircularProgress size={24} style={{ padding: '4px' }} />
                    ) : (
                      <button
                        disabled={isLoading}
                        className={
                          freight.paymentSuccessfulBalance
                            ? [classes.btn, classes.btnDisabled].join(' ')
                            : [classes.btn, classes.btnBlue].join(' ')
                        }
                      >
                        Pagamento de Saldo Realizado
                      </button>
                    )}
                  </>
                )
              ) : null}
            </div>
          </div>
        </div >
      ) : null}
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(Steps));